import Head from "next/head"
import { Box, Container } from "@mui/material"
import ErrorComponent from "src/core/components/ErrorComponent"

// ------------------------------------------------------
// This page is rendered if a route match is not found
// ------------------------------------------------------
export default function Page404() {
  const statusCode = 404
  const title = "This page could not be found"
  return (
    <>
      <Head>
        <title>
          {statusCode}: {title}
        </title>
      </Head>
      <Box
        position={"relative"}
        minHeight={"calc(100vh)"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        height={1}
        marginTop={-12}
        paddingTop={12}
      >
        <Container>
          <ErrorComponent statusCode={statusCode} title={title} />
        </Container>
      </Box>
    </>
  )
}
